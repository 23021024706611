import React from "react";
import { graphql } from "gatsby";
import PageHeader from "../components/PageHeader";
import Content from "../components/Content";
import Layout from "../components/common/Layout";
import Nav from "../components/common/Nav";

// Export Template for use in CMS preview
export const DefaultPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  body,
}) => (
  <main className="Investor">
    <section className="row section links space-100b space-300t">
      <div className="container" style={{ marginTop: "100px" }}>
        <Content source={body} />
      </div>
    </section>
  </main>
);

const DefaultPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <DefaultPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
);
export default DefaultPage;

export const pageQuery = graphql`
  query DefaultPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        featuredImage
      }
    }
  }
`;
