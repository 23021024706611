import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Image from './common/Image'
import { Link } from 'gatsby'
import { ChevronRight, ChevronLeft } from 'react-feather'
import { ImgComparisonSlider } from '@img-comparison-slider/react'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from './common/Button'

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  large,
  className = '',
  button,
  secondaryButton,
  date,
  authors,
  backButton,
  heroImage,
  homePage,
  tag,
  center,
  post
}) => {
  const [open, setOpen] = useState(false)

  return homePage ? (
    <>
      <Modal
        opened={open}
        onClose={setOpen}
        size="100%"
        withCloseButton={false}
      >
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/pfTTF1peJyE?si=dBbPfT29xQXwAlTg&amp;controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <h1 className={styles.title}>
              Where maps <br />
              come to life
            </h1>
            <div className={styles.descriptionContainer}>
              <div>
                <p className={styles.blueDescription}>Meet Atlas</p>
                <p>
                  The new standard for GIS software. Simple and powerful. Step
                  into Atlas to explore, analyze and share geospatial data.
                </p>
                <div style={{ marginTop: '1rem' }}>
                  <Button href="https://app.atlas.co/login">
                    Start for free
                  </Button>

                  <Button href="/contact" variant="secondary">
                    Book a demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.hero}>
            <img src="/images/hero-home.png" alt="Atlas Product" />
          </div>
          {/* {heroImage && (
            <div className="PageHeader--HomeHero taCenter">
              <video
                loop
                autoPlay
                muted
                className={'PageHeader--HomeHero-image'}
              >
                <source src="/product-overview.mp4" type="video/mp4" />
              </video>
            </div>
          )} */}
          <div className={styles.partnersGrid}>
            <div className={styles.partner}>
              <img src={'./images/logo-scatec.png'} alt={'Scatec Logo'} />
            </div>
            <div className={styles.partner}>
              <img
                src={'./images/logo-tronderenergi.png'}
                alt={'Trønderenergi Logo'}
              />
            </div>
            <div className={styles.partner}>
              <img src={'./images/logo-esa.png'} alt={'ESA Logo'} />
            </div>
            <div className={styles.partner}>
              <img src={'./images/logo-ntnu.png'} alt={'NTNU Logo'} />
            </div>
          </div>
        </div>
        <div className={styles.heroEllipse}>
          <img src="/images/Hero-ellipse.svg" alt="Atlas ellipse shape" />
        </div>
        {/* <div className=" row container skinnier taCenter quoteSection">
          <p style={{ fontWeight: '600', marginBottom: '2rem' }}>
            «Thanks to Enernite Platform, it’s now possible to check on all the
            factors in one place, and it will automatically show the best
            locations for solar farms.»
          </p>
          <p>Berenika Ensztejn</p>
          <p
            style={{ color: '#8F9BA8', marginTop: '-1rem', fontSize: '0.8em' }}
          >
            GIS Expert
          </p>
        </div> */}
      </div>
    </>
  ) : center ? (
    <div className={large ? 'PageHeader' : 'PageHeaderSmall'}>
      <div className="container">
        {heroImage && (
          <div className="heroImage">
            <Image
              src={heroImage}
              alt={title}
              className={'PageHeader--Hero-image'}
            />
          </div>
        )}

        {backButton && (
          <Link className="backButton" to={`/${backButton}`}>
            <ChevronLeft />
            Back to {backButton.charAt(0).toUpperCase() + backButton.slice(1)}
          </Link>
        )}
        <div className="taCenter container skinny">
          {tag && (
            <p className="PageHeader--Tag--Blue">
              <span>{tag}</span>
            </p>
          )}
          <h1 className="PageHeader--TitleCenter">{title}</h1>
          <p className="PageHeader--DescriptionCenter">{subtitle}</p>
          {authors && (
            <p className="PageHeader--Author">
              <strong>{authors.name}</strong>
              {authors.role ? ` - ${authors.role}` : ''}
            </p>
          )}
          {date && <p className="PageHeader--Date">{date}</p>}
          {button && (
            <Link className="Contact-button" to="/demo-request">
              Book Discovery Call
            </Link>
          )}
          {/* {secondaryButton && (
            <Link className="PageHeader--Button" to={secondaryButton}>
              Enernite Vision <ChevronRight />
            </Link>
          )} */}
        </div>
      </div>
    </div>
  ) : (
    <div className={large ? 'PageHeader' : 'PageHeaderSmall'}>
      <div className="container" style={{ position: 'relative' }}>
        {heroImage && (
          <video loop autoPlay muted className={'heroImage'}>
            <source src="/product-overview.mp4" type="video/mp4" />
          </video>
        )}

        {backButton && (
          <Link className="backButton" to={`/${backButton}`}>
            <ChevronLeft />
            Back to {backButton.charAt(0).toUpperCase() + backButton.slice(1)}
          </Link>
        )}

        {tag && (
          <div className="tagDiv">
            <p className="PageHeader--Tag">
              <span>{tag}</span>
            </p>
          </div>
        )}
        <h1 className="PageHeader--FrontTitle">{title}</h1>
        <p className="PageHeader--Description">{subtitle}</p>
        {authors && (
          <p className="PageHeader--Author">
            <strong>{authors.name}</strong>
            {authors.role ? ` - ${authors.role}` : ''}
          </p>
        )}
        {date && <p className="PageHeader--Date">{date}</p>}
        {button && (
          <a href="https://app.atlas.co/login" className="button">
            Start for free
          </a>
        )}
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
